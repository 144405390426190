import React from "react";
// Customizable Area Start
import { Button, FormControlLabel, Typography, RadioGroup, Radio, Checkbox, TextField, FormControl, FormLabel, Select, MenuItem, Modal, Dialog, Chip, DialogActions } from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import { configJSON } from "./CustomisableUserProfilesController";
import WorldFlag from 'react-world-flags';
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import MessageBox from "../../../components/src/messageBox";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Clear } from "@material-ui/icons";
const EditImg = require("../assets/Edit-Img.png");
const arrowSign = require("../assets/arrow-sign.png");
const deleteIcon = require("../assets/deleteIcon.png");
const photoLogo = require("../assets/photo.png");
const replacePicIcon = require("../assets/replacePicIcon.png");
const black_cross_sigin = require("../assets/cross_sigin.png");
const logout = require("../assets/logoutImg.png");
import {upload} from "./assets"
import { Country, State } from "country-state-city";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start    
    const toggle = this.state.toggleEditPersonalInfoAndBusinessInfo;
    const country = this.state.editPersonalInformation.country;
    const states = country ? State.getStatesOfCountry(country) : [];
    const mappedStates = states.map(state => ({ isoCode: state.isoCode, name: state.name }));   
    const showToggleBtns=()=>{
      if(this.state.role==="employee"){
        return;
      }
      return (<>
          <Box component="div" style={{ ...InformationStyle.PersonalInformationOption, color:this.handleCondition( toggle , "#325962","#64748B"), backgroundColor: this.handleCondition(toggle,"#E0EDF0","" )} as React.CSSProperties } data-test-id="PersonalInformationBox" onClick={() => this.handleChangePage(true)}>{configJSON.personalDetailsTxt}</Box>
          <Box component="div" style={{ ...InformationStyle.BusinessInformationOption, color: this.handleCondition(!toggle ,"#325962","#64748B"), backgroundColor:this.handleCondition( !toggle , "#E0EDF0","") }  as React.CSSProperties } data-test-id="BusinessInformationBox" onClick={() => this.handleChangePage(false)}>{configJSON.bussinessDetailsTxt}</Box>
      </>
      )
    };
    const handleRoleStatus=()=>{
      if(this.state.role==="employee"){
        return true;
      }else{
        return this.state.editDetailToggle;
      }
    } 
    return (
    <div onClick={this.handleClickOutsideReplaceRemovePic} data-test-id = "handleClickOutsideReplaceRemovePic">
      <StyledDialog open={this.state.showMessage}>
         <Chip
        label="User In Deletion Period you have view-only access during the 30-days reactivation"
        clickable
        variant="outlined"
        style={{fontWeight:400,fontSize:'16px',backgroundColor:'transparent',fontFamily:'Poppins',}}
      />
      </StyledDialog>
    <MyProfileHeader navigation={this.props.navigation} showSidebar={true} profilePhoto={this.state.updatedProfile}>
    {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status}/>}
      <style>
            {`
            .hover-effect:hover {
              background:#E0EDF0;
            }
            .MuiSelect-outlined.MuiSelect-outlined {
              background-color: white;
            }
            ::placeholder {
              font-size: 16px;
            }
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
              border: 1px solid rgb(203, 213, 225);
            }
            .MuiOutlinedInput-notchedOutline {
              border-color: rgb(203, 213, 225);
            }
            div[class="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"]{
              border-radius:8px;
              border: 1px solid rgb(203, 213, 225);
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
            }
            .MuiListItem-root.Mui-selected {
              background-color: rgba(224, 237, 240, 1);
            }
            .MuiListItem-root.Mui-selected:hover {
              background-color: rgba(224, 237, 240, 1);
            }

           #menu-role_id .MuiList-padding {
            padding-top: 0px;
            padding-bottom: 0px;
           }
           #menu-role_id li{
            padding:12px 16px 12px 16px;
           }
          `}
        </style>
      <Box component="div" style={this.state.role==="employee"?InformationStyle.PersonalInformationBox2:InformationStyle.PersonalInformationBox}>
        <Box component="div" style={InformationStyle.OptionBox}>
          <Box component="div" style={InformationStyle.singleOptionBox}>
            {showToggleBtns()}
          </Box>
        </Box>
        {this.state.toggleEditPersonalInfoAndBusinessInfo?<>
        <Grid container spacing={2} style={InformationStyle.PersonalInformationDetailBox}>
          <Grid item xs={12} md={2} style={InformationStyle.PersonalInfoImg}>
            <Box style={{...InformationStyle.personalInfoPhoto, backgroundImage:`url(${photoLogo})`} as React.CSSProperties} data-test-id="handleClickInsideReplaceRemovePic" onClick={this.handleClickInsideReplaceRemovePic}>
            {this.handleCondition(this.state.previewProfileImg ,
             <Box style={InformationStyle.PersonalInfoActualPhoto}>
              <img src={this.state.previewProfileImg} width={"100%"} height={"100%"} style={InformationStyle.photoImg as React.CSSProperties} />
              </Box>,'')}
            {this.handleCondition(this.state.editPicToggle ,<Box
             style={InformationStyle.editImg as React.CSSProperties} 
             data-test-id="replaceRemovePicToggle"
              onClick={()=> { return !this.state.editDetailToggle && this.replaceRemovePicToggle()}}>
                <img src={EditImg} />
                </Box>,'')}
            {this.handleCondition(!this.state.editPicToggle , 
            <Box style={InformationStyle.editpic as React.CSSProperties} >
              {this.state.previewProfileImg?
              <>
              <Box className="hover-effect" style={InformationStyle.replaceRemovePhoto}> 
               <label htmlFor="file-upload" style={{ padding: "9px 20px 9px 16px" ,display:"flex" ,justifyContent:"center", alignContent:"center", gap:"8px" }} ><img src={replacePicIcon} />  Replace Photo </label>
               <input 
                data-test-id="file-change" 
                id="file-upload" type="file" 
                onChange={(event)=>this.handleFileChange(event,"profile")} 
                style={{ display: 'none' }}  />
              </Box>
              <Box className="hover-effect" style={{...InformationStyle.replaceRemovePhoto, padding: "9px 20px 9px 16px"}} data-test-id="removePhoto"
               onClick={()=>this.removePhoto('profile')}> <img src={deleteIcon} /> Remove photo </Box>
              </>
              :<Box className="hover-effect" style={InformationStyle.replaceRemovePhoto}> 
              <label htmlFor="file-upload" style={{ padding: "8px 16px 8px 16px" ,display:"flex" ,justifyContent:"center", alignItems:"center", gap:"8px",fontSize:"14px",fontFamily:"Poppins" }} ><img src={upload} width={"18px"} height={"18px"}/>  Upload Photo </label>
              <input 
               data-test-id="file-change-new" 
               id="file-upload" type="file" 
               onChange={(event)=>this.handleFileChange(event,"profile")} 
               style={{ display: 'none' }}  />
             </Box>
              }
            </Box> 
            , '')}
            </Box>
          </Grid><Grid item xs={12} md={10} ><Grid container><Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <p style={InformationStyle.PITitle}>Personal Information</p>
                    <p style={InformationStyle.PIDesc}>Manage your personal information including name, email, and phone number.</p>
                  </Grid >
                  <Grid item xs={12} sm={3}>
                    {this.handleCondition(this.state.editDetailToggle ,<p style={InformationStyle.editDetails as React.CSSProperties}>
                      <span style={{ cursor: "pointer", opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1') }} data-test-id="edit-detail-text" 
                      onClick={()=>this.state.deletionAccountDetails?.data?.attributes?.deletion_status !== "pending_deletion"&&this.editDetailsToggle()}>Edit Details</span>
                      </p>,'')}
                    {this.handleCondition(!this.state.editDetailToggle , <Box style={{...InformationStyle.editDetails, ...InformationStyle.cancelSaveBtn} as React.CSSProperties}>
                      <Button onClick={this.cancelEditDetails} data-test-id="cancelEditDetails" style={InformationStyle.cancelBtn as React.CSSProperties}> Cancel </Button>
                      <Button data-test-id="save-changes-button" style={this.desiableCheck() ? InformationStyle.saveChangesDisabled as React.CSSProperties : InformationStyle.saveChanges as React.CSSProperties} onClick={this.editPersonalInformationAPI} disabled={this.desiableCheck()}>
                        Save Changes
                      </Button>                 
                    </Box> , '')}
                  </Grid>
                </Grid>
              </Grid><Grid item xs={12} style={{ marginTop: "24px" }}><Grid container spacing={2}><Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>First Name {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')} </label>
                      <input 
                      type='text'
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter first name" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.first_name,"#F87171","#CBD5E1")}`,
                      opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}} 
                      value={this.state.editPersonalInformation.first_name} 
                      data-test-id="handleChangeEdit" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "first_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.first_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Last Name {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')} </label>
                      <input 
                      type='text' 
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter last name" style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.last_name,"#F87171","#94A3B8")}`
                                                            , opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}} 
                      value={this.state.editPersonalInformation.last_name} 
                      data-test-id="handleChangeEditlast_name" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "last_name")} 
                      />
                      <p style={InformationStyle.errorShow}>{this.state.editError.last_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Phone Number {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')}</label>
                      <div style={{ display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                        <Select style={{ ...InformationStyle.textinputBoxedCountryCodePN,
                        border:"none", height: '55px', marginTop:"4px", width:"150px" }} data-test-id="txtInputCountryCode" variant="outlined" name="country_code" value={`+${this.state.editPersonalInformation.country_code}`} onChange={this.EditCountryCode} displayEmpty disabled={this.state.editDetailToggle}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null, // Ensures the menu opens from the bottom of the Select component
                          PaperProps: {
                            style: {
                              width:"130px",
                            },
                          },
                        }}
                            open={this.state.piToggleDropDown}
                            onOpen={() => this.handleTogglePIDropDown()}
                            onClose={() => this.handleTogglePIDropDown()}
                            IconComponent={() => (
                              <div
                                onClick={(e) => {this.handleTogglePIDropDown();}}
                                style={{ marginRight: "8px" }}
                                data-test-id="txtInputCountryCodeIcon"
                              >
                                {this.handleCondition(this.state.piToggleDropDown , (
                                  <KeyboardArrowUpIcon style={{ cursor: "pointer", color:"#64748B" }}/>
                                ) , (
                                  <KeyboardArrowDownIcon style={{ cursor: "pointer", color:"#64748B" }}/>
                                ))}
                              </div>
                            )}
                        > 
                          {configJSON.countryCodes.map((country: { dial_code: {} | null | undefined; code: React.Key | null | undefined; }) => (
                            <MenuItem value={country.dial_code as string} key={country.code}>
                            <Box display="flex" alignItems="center" style={{gap:'5px'}}>
                            <WorldFlag code={country.code as string} height="18" width="24" style={{borderRadius: "1px", marginBottom: "0px" }} />
                            <Typography style={{color:"#1E293B",fontSize: '16px', fontFamily: 'Poppins', lineHeight: '24px'}}>&nbsp;{country.dial_code}</Typography>
                            </Box>
                            </MenuItem>
                          ))}
                        </Select>
                        <input 
                        type='text' 
                        disabled={this.state.editDetailToggle} 
                        placeholder="000 0000 0000" 
                        style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.phone_number,"#F87171","#94A3B8")}`,}} 
                        value={this.state.editPersonalInformation.phone_number} 
                        data-test-id="handleChangeEditphone_number" 
                        onChange={(e) => this.handleChangeEdit(e.target.value, "phone_number")} 
                        />
                      </div>
                      <p style={InformationStyle.errorShow}>{this.state.editError.phone_number}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <Box className="role-dropDown-list">
                        <label style={InformationStyle.inputLabel}>Role {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')}</label>
                        <Select
                          style={{ ...InformationStyle.textinputBoxedCountryCodePN, border: "none", width: '100%', height: '55px', marginTop: "4px" }}
                          data-test-id="handleChangeEditrole_id"
                          variant="outlined"
                          name="role_id"
                          placeholder="Choose your role"
                          value={this.state.editPersonalInformation.role_id}
                          onChange={(e) => this.handleChangeEdit(e.target.value as string, "role_id")}
                          displayEmpty disabled={handleRoleStatus()}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null, // Ensures the menu opens from the bottom of the Select component
                            PaperProps: {
                              style: {
                                width:"35.5%",
                              },
                            }                        
                          }}
                          open={this.state.piToggleRoleDropDown}
                          onOpen={() => this.handleToggleRolePIDropDown()}
                          onClose={() => this.handleToggleRolePIDropDown()}
                          IconComponent={() => 
                           !handleRoleStatus()? <div
                              onClick={(e) => {this.handleToggleRolePIDropDown(); }}
                              style={{ marginRight: "8px" }}
                              data-test-id="txtInputCountryCodeIcon"
                            >
                              {this.handleCondition(this.state.piToggleRoleDropDown , (
                                <KeyboardArrowUpIcon style={{ cursor: "pointer", color: "#64748B" }} />
                              ),(
                                <KeyboardArrowDownIcon style={{ cursor: "pointer", color: "#64748B" }} />
                              ))}
                            </div>:<></>
                          }
                        >
                          <MenuItem value={"owner"} style={InformationStyle.dropdownRoleText}>Owner</MenuItem>
                          <MenuItem value={"manager"} style={InformationStyle.dropdownRoleText}>Manager</MenuItem>
                          <MenuItem value={"employee"} style={InformationStyle.dropdownRoleText}>Employee</MenuItem>
                        </Select>
                        <p style={InformationStyle.errorShow}>{this.state.editError.role_id}</p>
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Job Title {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')}</label>
                      <input 
                      type='text' 
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter your job title" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.job_title,"#F87171","#94A3B8")}`
                             , opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}} 
                      value={this.state.editPersonalInformation.job_title} 
                      data-test-id="handleChangeEditjob_title" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "job_title")} 
                      />
                      <p style={InformationStyle.errorShow}>{this.state.editError.job_title}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>About Me {this.handleCondition(!this.state.editDetailToggle , <span>*</span> , '')}</label>
                      <textarea 
                      style={{...InformationStyle.textArea, border:`1px solid ${this.handleCondition(this.state.editError.about_me,"#F87171","#94A3B8")}`,
                       opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1') } as React.CSSProperties} 
                      placeholder="Write something about yourself" 
                      disabled={this.state.editDetailToggle} 
                      value={this.state.editPersonalInformation.about_me} 
                      data-test-id="handleChangeEditabout_me" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "about_me")}> 
                      </textarea>
                      <p style={InformationStyle.errorShow}>{this.state.editError.about_me}</p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ ...InformationStyle.PersonalInformationDetailBox }}>
          <Grid item xs={12}>
           <p style={InformationStyle.accountText}>Account Settings</p>
            <p style={InformationStyle.AccPrefAndsetting}>Manage your account preferences and settings here.</p>
          </Grid>
          <Grid item xs={12}>
            <Box style={InformationStyle.EmailPasswordBox}>
            {this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , 
            <p style={InformationStyle.changeEmail} data-test-id="changePassword" >Change Password</p> ,
            <> <p style={InformationStyle.pdEmText} data-test-id="changePassword" onClick={()=>{ this.navigatePage(true)}}>Change Password</p>
            <img src={arrowSign} style={{cursor:"pointer"}} data-test-id="changePasswordSec" onClick={()=>{ this.navigatePage(true)}}/></>)} 
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={InformationStyle.EmailPasswordBox}>
              {this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" ,
               <p style={InformationStyle.changeEmail} data-test-id="changeEmail">Change Email</p>,
              <><p style={InformationStyle.pdEmText} data-test-id="changeEmail" onClick={() => { this.navigatePage(false); } }>
                Change Email</p>
                <img src={arrowSign} style={{ cursor: "pointer" }} data-test-id="changeEmailSec" onClick={() => { this.navigatePage(false); } } /></>)}
            </Box>
          </Grid>
          {this.state.role==="employee"&&
          <Grid item xs={12}>
            <Box style={InformationStyle.logoutBox} data-test-id="logoutBtn" onClick={()=>this.openLogout()}>
            <img src={logout} width="20px" height="20px" color="#334155"/>
            <Typography style={{fontSize:"14px",fontWeight:600,fontFamily:"Poppins",color:"#334155"}}>{configJSON.logout}</Typography>
            </Box>
          </Grid>}
        </Grid>
        <Grid container spacing={2} style={{ ...InformationStyle.PersonalInformationDetailBox, padding: "32px" }}>
          <Grid item xs={12}>
            <img src={deleteIcon} style={InformationStyle.deleteAccountIcon} data-test-id="deleteAccountModelBtn1" 
            onClick={()=> this.state.deletionAccountDetails.data?.attributes?.deletion_status !== "pending_deletion"&&this.deleteAccount()} />
            <p data-test-id="deleteAccountModelBtn2" style={InformationStyle.deleteAccountTxt} onClick={()=> this.state.deletionAccountDetails?.data?.attributes?.deletion_status !== "pending_deletion"&&this.deleteAccount()}>Delete Account</p>
            <p style={{ margin: "8px 0px 0px 0px" }}>We'd hate to see you leave, but you can delete your account at any time.</p></Grid>
        </Grid></>
        :
        <Grid container spacing={2} style={InformationStyle.PersonalInformationDetailBox}>
          <Grid item xs={12} md={2} style={InformationStyle.PersonalInfoImg}>
            <Box style={{...InformationStyle.personalInfoPhoto, backgroundImage:`url(${photoLogo})`} as React.CSSProperties} onClick={this.handleClickInsideReplaceRemovePic}>
            {this.handleCondition(this.state.previewImage ,
            <Box style={InformationStyle.PersonalInfoActualPhoto}><img src={this.state.previewImage}
             onClick={()=>{ this.viewFullScreen(this.state.previewImage) }} data-test-id="viewFullScreen" width={"100%"} height={"100%"} 
             style={InformationStyle.photoImg as React.CSSProperties} /></Box>,'')}
            {this.handleCondition(this.state.editPicToggle ,
             <Box style={InformationStyle.editImg as React.CSSProperties} data-test-id="replaceRemovePicToggle"
              onClick={()=>{!this.state.editDetailToggle && this.replaceRemovePicToggle() }}><img src={EditImg} /></Box>,'')}
            {this.handleCondition(!this.state.editPicToggle , <Box style={InformationStyle.editpic as React.CSSProperties} >
              <Box className="hover-effect" style={InformationStyle.replaceRemovePhoto}> 
               <label htmlFor="file-upload" style={{padding: "9px 20px 9px 16px"}} ><img src={replacePicIcon} />  Replace Photo </label>
               <input data-test-id="file-change" id="file-upload" type="file" onChange={(event)=>this.handleFileChange(event,"bussiness")} style={{ display: 'none' }}  />
              </Box>
              <Box className="hover-effect" style={{...InformationStyle.replaceRemovePhoto, padding: "9px 20px 9px 16px"}} data-test-id="removePhoto" onClick={()=>{ this.removePhoto("bussiness") }}> 
              <img src={deleteIcon} /> Remove photo </Box>
            </Box>,'')}
            </Box>
          </Grid>
          <Grid item xs={12} md={10} >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <p style={InformationStyle.PITitle}>Business Information</p>
                    <p style={InformationStyle.PIDesc}>Manage your business information including company name, billing address etc.</p>
                  </Grid >
                  <Grid item xs={12} sm={3}>
                    {this.handleCondition(this.state.editDetailToggle ,
                    <p style={InformationStyle.editDetails as React.CSSProperties}><span style={{ cursor: "pointer", opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.1','1')  }} data-test-id="businessEditDetails"
                     onClick={()=>this.state.deletionAccountDetails.data?.attributes?.deletion_status !== "pending_deletion"&&this.editDetailsToggle()}>Edit Details</span></p>,'')}
                    {this.handleCondition(!this.state.editDetailToggle , <Box style={{...InformationStyle.editDetails, ...InformationStyle.cancelSaveBtn} as React.CSSProperties}>
                      <Button onClick={this.cancelEditDetails} style={InformationStyle.cancelBtn as React.CSSProperties}> Cancel </Button>
                      <Button
                        data-test-id="save-changes-button"
                        style={this.desiableCheck() ? InformationStyle.saveChangesDisabled as React.CSSProperties : InformationStyle.saveChanges as React.CSSProperties}
                        onClick={this.editPersonalInformationAPI}
                        disabled={this.desiableCheck()}
                        >
                        Save Changes
                      </Button>                 
                    </Box>,'')}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "24px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Company Name</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter company name"
                       style={{...InformationStyle.InputText, border:`1px solid 
                       ${this.handleCondition(this.state.editError.company_name,"#F87171","#94A3B8")}`,  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}} 
                       value={this.state.editPersonalInformation.company_name} data-test-id="handleChangeEditcompanyName" 
                       onChange={(e) => this.handleChangeEdit(e.target.value, "company_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.company_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Company Number</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter company number" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.company_number,"#F87171","#94A3B8")}`,  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                       value={this.state.editPersonalInformation.company_number} data-test-id="handleChangeEditcompany_number" 
                       onChange={(e) => this.handleChangeEdit(e.target.value, "company_number")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.company_number}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>VAT</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter vat" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.vat,"#F87171","#94A3B8")}`,  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                       value={this.state.editPersonalInformation.vat} data-test-id="handleChangeEditvat" 
                       onChange={(e) => this.handleChangeEdit(e.target.value, "vat")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.vat}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Street Name</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter street name" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.street_name,"#F87171","#94A3B8")}`,  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                       value={this.state.editPersonalInformation.street_name} data-test-id="handleChangeEditstreet_name"
                        onChange={(e) => this.handleChangeEdit(e.target.value, "street_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.street_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>City</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter city" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.city,"#F87171","#94A3B8")}`,  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                       value={this.state.editPersonalInformation.city} data-test-id="handleChangeEditcity"
                        onChange={(e) => this.handleChangeEdit(e.target.value, "city")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.city}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Zip code</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter zip code"
                       style={{...InformationStyle.InputText, border:`1px solid ${this.handleCondition(this.state.editError.zip_code,"#F87171","#94A3B8")}`,
                         opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                        value={this.state.editPersonalInformation.zip_code} data-test-id="handleChangeEditzip_code"
                         onChange={(e) => this.handleChangeEdit(e.target.value, "zip_code")} />                      
                      <p style={InformationStyle.errorShow}>{this.state.editError.zip_code}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>State</label>
                      <Select
                        style={{
                          height: "56px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                        }}
                        placeholder="Enter state"
                        variant="outlined"
                        name="state"
                        data-test-id="handleChangeEditState"
                        value={this.state.editPersonalInformation.state}
                        disabled={this.state.editDetailToggle}
                        onChange={(e) => { this.handleChangeEdit(e.target.value as string, "state")}}
                        // error={stateError}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              height: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select State</MenuItem>
                        {
                          mappedStates.map((state, index) => (
                            <MenuItem key={index} value={state.isoCode}>
                              <Typography style={InformationStyle.dropdownRoleText}>
                              {state.name}
                              </Typography>
                            </MenuItem>
                          ))}
                      </Select>
                      <p style={InformationStyle.errorShow}>{this.state.editError.state}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Country</label>
                            <Select
                              style={{
                                height: "56px",
                                backgroundColor: "white",
                                borderRadius: "8px",
                                fontFamily: "Poppins, sans-serif",
                                width: "100%",
                              }}
                              value={this.state.editPersonalInformation.country}
                              onChange={(e) => { this.handleChangeEdit(e.target.value as string, "country"); } }
                              placeholder="Select Country"
                              variant="outlined"
                              name="country"
                              data-test-id="handleChangeEditCountry"
                              disabled={this.state.editDetailToggle}
                              displayEmpty
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    position: "fixed",
                                    height: "300px",
                                    width: "20px",
                                    right: "500px",
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">Select country</MenuItem>
                              {Country.getAllCountries().map((country, index) => (
                                <MenuItem key={index} value={country.isoCode}>
                                   <Typography style={InformationStyle.dropdownRoleText}>
                                       {country.name}
                                    </Typography>
                                </MenuItem>
                              ))}

                            </Select>
                      <p style={InformationStyle.errorShow}>{this.state.editError.country}</p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
      </Box>
            <Modal
              open={this.state.popUpToggle}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              data-test-id="Warning-Modal"
            >
              <div style={InformationStyle.modalPaper as React.CSSProperties}>
                <div style={InformationStyle.titleCloseDiv}>
                <h2 id="simple-modal-title" style={InformationStyle.modalHeading}>Warning</h2>
                <Typography data-test-id="Clear-icon" onClick={() => this.popUpToggle()}
                        style={InformationStyle.clearIcon}><Clear /></Typography>
                </div>
                <p id="simple-modal-description" style={InformationStyle.modalDesc}>
                  Would you like to go to the next page without saving?
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "40px" }}>
                  <Button
                    onClick={() => this.popUpToggle()}
                    style={InformationStyle.modalCancelBtn as React.CSSProperties}
                    data-test-id="CancelPage"
                  >
                    Cancel
                  </Button>
                  <Button data-test-id="ContinueAndChangePage" onClick={() => this.continueChanges(!this.state.toggleEditPersonalInfoAndBusinessInfo)}
                   style={InformationStyle.modalContinueBtn as React.CSSProperties}>
                    Continue
                  </Button>
                </div>
              </div>
            </Modal>
          <Modal
            open={this.state.deleteAccountToggle}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{ ...StyleSheet.modalPaper, width: "584px" } as React.CSSProperties}>
              <Box style={StyleSheet.modelHeadingBox}>
                <h2 style={StyleSheet.modelHeading}>Confirm Account Deletion</h2>
                <img src={black_cross_sigin} style={{ cursor: "pointer" }} width={"20px"} height={"20px"} onClick={this.cancelAccountPopUpToggle} />
              </Box>
              <p style={StyleSheet.ConfirmationText}>
                To delete your account, please enter your current password and type 'DELETE ACCOUNT' in the field below. Deleting your account will deactivate your and all company related accounts. <b> You can reactivate by logging in within 30 days & confirm reactivation. </b>
              </p>
              <Box style={{ position: "relative", marginBottom: "24px" }}>
                <label style={{ ...StyleSheet.inputLabel, color: "rgba(51, 65, 85, 1)" }}> Current Password </label>
                <InputContainer >
                <input type="text" name="password"
                  onBlur={this.state.deleteAccountPassword.length !== 0 ? this.checkCurrentPassword : () => {}}
                  style={{ ...StyleSheet.inputPlaceholderText, backgroundColor: this.handleCondition(this.state.deleteAccountPassword , "rgba(255, 255, 255, 1)" , "rgba(252, 253, 254, 1)")
                    , border:this.handleCondition( this.state.currentPasswordError , "1px solid #F87171" , "1px solid #CBD5E1" ),  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1')}}
                  value={this.handleCondition(this.state.showAccountCurrentPassword , this.state.asteriskAccountCurrent , this.state.deleteAccountPassword)}
                  data-test-id="deleteAccountCurrentPassword"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAccountCurrentPasswordChange(e)}
                  placeholder="Enter your current password"
                />
                </InputContainer >

                {this.handleCondition(this.state.showAccountCurrentPassword , (
                  <VisibilityOffOutlinedIcon
                    data-test-id="IconShowCurrentPasswordAcc"
                    style={StyleSheet.EyePassward as React.CSSProperties}
                    onClick={this.toggleAccountVisibilityToggle}
                  />
                ) , (
                  <VisibilityOutlinedIcon
                    data-test-id="IconHideCurrentPasswordAcc"
                    style={StyleSheet.EyePassward as React.CSSProperties}
                    onClick={this.toggleAccountVisibilityToggle}
                  />
                ))}
                <p style={InformationStyle.errorShow}>{ this.state.currentPasswordError }</p>
              </Box>
              <Box style={{ position: "relative" }}>
                <label style={{ ...StyleSheet.inputLabel, color: "rgba(51, 65, 85, 1)" }}>Type ‘DELETE ACCOUNT’ to confirm</label>
                <InputContainer >
                <input type="text"
                  style={{ ...StyleSheet.inputPlaceholderText, backgroundColor: this.handleCondition(this.state.deleteAccountType , "rgba(255, 255, 255, 1)" , "rgba(252, 253, 254, 1)"),
                    border: this.handleCondition(this.state.AccountTypError , "1px solid #F87171" , "1px solid #CBD5E1"),  opacity:this.handleCondition(this.state.deletionAccountDetails?.data?.attributes?.deletion_status ===  "pending_deletion" , '0.6','1') }}
                 value={this.state.deleteAccountType}
                 data-test-id="handleChangeAccType"
                 onChange={(e) => this.handleChangeAccType(e.target.value)}
                 placeholder="“DELETE ACCOUNT”"
                 />
                </InputContainer>

                {this.handleCondition(this.state.AccountTypError , <p style={InformationStyle.errorShow}>Please type 'DELETE ACCOUNT' exactly to proceed.</p>,'')}
              </Box>
              {this.state.CommonDeletionAccountError && <p style={{...InformationStyle.errorShow, marginTop:"5px"}}>{ this.state.CommonDeletionAccountError }</p>}
              <div style={StyleSheet.footerBtnGroup}>
                <Button
                  onClick={this.cancelAccountPopUpToggle}
                  style={{ ...StyleSheet.cancelBtn, backgroundColor: "#E0EDF0" } as React.CSSProperties}
                  data-test-id="cancelAccountPopUpToggle"
                >
                  Cancel
                </Button>
                <Button
                  data-test-id="deleteAccountApiCall"
                  onClick={() => this.deleteAccountApiCall()} 
                  style={this.desiableAccountCheck() ? StyleSheet.saveChanges as React.CSSProperties : InformationStyle.saveChangesDisabled as React.CSSProperties}
                  disabled={!this.desiableAccountCheck()}
                >
                  Delete Account
                </Button>
              </div>
            </div>
          </Modal>
          <Dialog
                  PaperProps={{
                    style:InformationStyle.logoutDialog
                  }}
                  data-test-id="logoutModal"
                  open={this.state.showLogout}
                  onClose={this.openLogout}
                >
                  <Box sx={{ padding: 2 }}>
                    <Box
                      style={InformationStyle.headerBox}
                    >
                      <Typography style={InformationStyle.title}>
                       {configJSON.headerLogout}
                      </Typography>{" "}
                      <Typography
                       data-test-id="logoutModalClose"
                        onClick={()=>this.openLogout()}
                        style={InformationStyle.clearIcon}
                      >
                        <Clear />
                      </Typography>
                    </Box>

                    <Typography
                      style={InformationStyle.logoutText}
                    >
                      {configJSON.baseLogout}
                    </Typography>
                    <DialogActions
                      style={InformationStyle.actionBox}
                    >
                      <Button
                       data-test-id="logoutModalCancel"
                        style={InformationStyle.cancelButton}
                        onClick={()=>this.openLogout()}
                      >
                        {configJSON.cancel}
                      </Button>
                      <Button
                       data-test-id="logoutModalSubmit"
                        style={InformationStyle.logoutButton}
                        onClick={()=>this.handleLogout()}
                      >
                        {configJSON.logout}
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
    </MyProfileHeader>
        </div>);
    // Customizable Area End
  }
}

// Customizable Area Start
const InformationStyle = {
  logoutDialog:{
    width: "584px",
    maxWidth: "584vw",
    height: "245px",
    maxHeight: "240vh",
    borderRadius: "16px",
    padding: "40px 32px 40px 32px",
    color: "#FFFFFF",
  },
  actionBox:{
    marginTop: "80px",
    gap: "10px",
  },
  logoutText:{
    fontSize: "16px",
    fontWeight: 400,
    paddingLeft: "",
    paddingRight: "80px",
    color: "#1E293B",
    fontFamily: "Poppins",
    marginTop: "2px",
  },
  titleCloseDiv: {display: "flex", alignItems: 'center', justifyContent: 'space-between', padding: '0px', height: '40px'},
  clearIcon:{
    color: "black",
    marginRight: "12px",
    cursor: "pointer",
  },
  closeIcon:{
    color: "black",
    width: "20px",
    marginTop: "8px",
    marginRight:"15px"
  },
  headerBox:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoutBox:{
    padding: "16px 0px",
    display: "flex",
    alignItems:"center",
    gap:"8px",
    cursor:"pointer"
  },
  title: {
    fontWeight: 900,
    fontSize: "26px",
    color: "#0F172A",
    fontFamily: "Poppins",
  },
  cancelButton: {
    fontWeight: 600,
    backgroundColor: "#E2E8F0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "96px",
  } as const,
  logoutButton: {
    fontWeight: 600,
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "94px",
  } as const,
  PersonalInformationBox: {
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins",
    marginLeft:"6rem"
  },
  PersonalInformationBox2: {
    padding: "0px 48px 0px 48px",
    fontFamily: "Poppins",
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    ...(window.innerWidth < 600 && {
      textAlign: "center"
    }),
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    lineHeight: "27px",
    margin: "0px",
    ...(window.innerWidth < 600 && {
      textAlign: "center"
    })
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "50px",
    padding: "4px"
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px"
  },
  personalInfoPhoto: {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
    position: "relative",
    border: "3.15px solid #E0EDF0",
    backgroundColor: "#D3E4E9", 
    backgroundRepeat:"no-repeat",
    backgroundPosition: "center"
  },
  PersonalInfoImg: {
    display: "flex",
    justifyContent: "center"
  },
  editDetails: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "right",
    color: "#237182",
    marginTop: "0px"
  },
  editImg: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    height: "32px",
    width: "32px",
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    backgroundColor: "#E0EDF0",
    borderRadius: "50%"
  },
  editpic:{
    backgroundColor: "white",
    position: "absolute",
    top: "80px",
    left: "100px",
    cursor: "pointer",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "4px",
    ...(window.innerWidth < 600 && {
    top:"115px",
    left:"20px"
    })
  },
  errorLabel: {
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '0.00938em',
    lineHeight: 1.5,
    color: 'red',
    margin: '5px 3px',
    fontSize: '14px',
    userSelect: 'none',
  },
  inputLabel: {
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    color: "#64748B",
    fontFamily: "Poppins",
  },
  PITitle: {
    fontWeight: 700,
    margin: "0px",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#0F172A"
  },
  textArea: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "10px 8px",
    fontFamily:"Poppins",
    marginTop: "4px",
    outline:"none",
    resize:"vertical",
    backgroundColor:"white",
    color:"#0F172A"
  },
  PersonalInformationDetailBox: {
    marginTop: "24px",
    padding: "30px 32px 30px 32px",
    backgroundColor: "white",
    borderRadius: "16px"
  },
  accountText: {
    fontSize: "18px",
    fontWeight: 700,
    margin: "0px",
    lineHeight: "26px"
  },
  AccPrefAndsetting: {
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0px",
    color: "#475569"
  },
  pdEmText: {
    margin: "0px",
    fontWeight: 600,
    color: "#334155",
    cursor:"pointer",
    fontFamily: 'Poppins'
  },
   changeEmail: {
    margin: "0px",
    fontWeight: 600,
    color: "#94A3B8",
    cursor:"pointer"
  },
  EmailPasswordBox: {
    padding: "16px 0px",
    display: "flex",
    justifyContent: "space-between"
  },
  PIDesc: {
    fontSize: "14px",
    margin: "0px",
    lineHeight: "22px",
    color: "#475569"
  },
  photoImg: {
    objectFit:"cover"
  },
  InputText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily:"Poppins",
    outline: "none",
    backgroundColor:"white",
    color:"#1E293B",
    fontSize: '16px'
  },
   disableText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily:"Poppins",
    outline: "none",
    backgroundColor:"white",
    color:"#94A3B8"
  },
  textinputBoxedCountryCodePN: {
    backgroundColor: "white",
    borderRadius: "8px",
    width: '120px',
    height: '55px',
    fontFamily:"Poppins"
  },
  cancelBtn: {
    color: "#237182",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    borderRadius: "8px",
    padding: "0.635rem 1rem",
  },
  modalCancelBtn: {
    color: "#325962",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: '16px',
    lineHeight: '24px',
    backgroundColor: '#E0EDF0',
    textTransform: "none",
    width: '90px', 
    height: '56px',
    padding: '16px',
    borderRadius: '8px'
  },
  modalContinueBtn: {
    color: "#fff",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: '16px',
    lineHeight: '24px',
    backgroundColor: '#237182',
    textTransform: "none",
    width: '121px', 
    height: '56px',
    padding: '16px',
    borderRadius: '8px',
    marginLeft: "24px",
  },
  saveChanges: { 
    color: "white", 
    fontWeight: 600, 
    backgroundColor: "#237182", 
    textTransform: "capitalize", 
    fontFamily: "Poppins", 
    marginLeft: "24px",
    borderRadius: "8px",
    padding: "0.635rem 1rem",
    fontStyle: '16px'
  },
  saveChangesDisabled: { 
    color: '#94A3B8',
    backgroundColor: '#F1F5F9',
    fontWeight: 600, 
    textTransform: "capitalize", 
    fontFamily: "Poppins", 
    marginLeft: "24px",
    borderRadius: "8px",
    padding: "0.635rem 1rem",
    fontSize: '16px'
  },
  cancelSaveBtn:{
    ...(window.innerWidth < 600 && {
      marginTop: '25px'
    })
  },
  replaceRemovePhoto:{
    whiteSpace: "nowrap", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    gap: "8px",
    borderRadius:"8px"
  },
  PersonalInfoActualPhoto:{ 
    overflow:"hidden",
    width:"100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor:"white"
  },
  errorShow:{ 
    color: "#DC2626",
    margin: "0px", 
    fontSize:"12px",
    fontFamily: "Poppins",
    padding: '0px'
  },
  modalPaper:{
    position: 'absolute',
    width: '585px',
    height: '252px',
    backgroundColor: "#fff",
    padding: "40px 20px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)",
    borderRadius: '16px',
    boxSizing: 'border-box'
  },
  modalHeading: {fontWeight: 700, fontSize: '24px', lineHeight: '32px',fontFamily: 'Poppins'},
  modalDesc: {fontWeight: 400, fontSize: '16px', lineHeight: '24px', fontFamily: 'Poppins'},
  deleteAccountTxt:{ 
    display: "inline-block", 
    color: "#DC2626", 
    fontSize: "18px", 
    fontWeight: 700, 
    margin: "0px", 
    cursor:"pointer"
  },
  deleteAccountIcon:{ 
    display: "inline-block", 
    marginBottom: "-5px", 
    marginRight: "8px", 
    cursor:"pointer" 
  },
  dropdownRoleText: {
    color: '#0F172A', 
    fontFamily: 'Poppins', 
    fontSize: '16px', 
    fontWeight: '400'
  }
}
const StyleSheet = {
  EyePassward: {
    cursor: 'pointer',
    color: '#94A3B8',
    position: "absolute",
    right: "18px",
    top: "40px"
  },
  inputLabel: {
    display: "block",
    fontSize: "14px",
    fontWeight: 600,
    color: "#64748B",
    fontFamily:"Poppins",
    lineHeight: '22px',
  },
  inputPlaceholderText: {
    display: "block",
    padding: "10px 40px 10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Poppins",
    outline: "none",
    fontSize: '16px',
  },
  InputText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Poppins",
    outline: "none"
  },
  cancelBtn: {
    color: "#325962",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    borderRadius: "8px",
    padding: "0.635rem 1rem",
    fontSize: '16px',
    lineHeight: '24px'
  },
  saveChanges: {
    color: "white",
    fontWeight: 600,
    backgroundColor: "#237182",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    marginLeft: "24px",
    borderRadius: "8px",
    padding: "0.635rem 1rem",
    fontSize: '16px',
  },
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: "#fff",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "16px",
    border: "none",
    padding: "40px 32px 40px 32px"
  },
  modelHeading:{
    fontWeight: 700,
    fontFamily: "Poppins",
    color:"#0F172A",
    margin:"0px",
  },
  modelHeadingBox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom:"12px"
  },
  ConfirmationText:{
    color:"#1E293B", 
    fontFamily: "Poppins",
    margin: "0px 0px 24px 0px",
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  footerBtnGroup:{
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "32px"
  },
}
const StyledDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor:'transparent',
  "& .MuiBackdrop-root": {
      backgroundColor: 'transparent',
  },
 '& .MuiDialog-container': {
    height: '85%',
    outline: 0,
    width:'100%',
    alignItems:'end',
    paddingBottom:'20px'
},
'& .MuiChip-outlinedSecondary':{
   border:'none'
},
'& .MuiChip-outlined':{
  border:'none'
},
'& .MuiDialog-paperWidthSm':{
  borderRadius:'8px',
  maxWidth:'800px'
}
}));

const DialogBoxes = styled(Dialog)(({ theme }) => ({
  backgroundColor:'blues',"& .MuiBackdrop-root": {  backgroundColor: 'transparent',},
 '& .MuiDialog-container': {
    height: '10%', outline: 0,
    width:'100%'
},'& .MuiChip-outlinedSecondary':{ border:'none'},
'& .MuiChip-outlined':{border:'none'},
"modelHeadingBox":{
  display:"flex",justifyContent:"space-between",
  alignItems:"center",marginBottom:"12px"
},
}));

const InputContainer = styled("div")(({ theme }) => ({
  '& input::placeholder':{
    color: '#94A3B8'
  }
})); 

// Customizable Area End
