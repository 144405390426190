import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  plans:{
      id: number;
      name: string;
      price: number;
      interval: string;
      stripe_plan_id: string;
      price_id: string | null;
      stripe_product_id: number;
      created_at: string;
      updated_at: string;
      discount_percentage: number | null;
  }[];
  currentPlan:{
    id:number;
    name:string;
    planId:string;
    validTill:string;
  };
  modalConfirm:{
    open:boolean;
    plan:string;
    price:number;
    interval:string;
    planId:string;
  };
  tostifymessage:{
    show:boolean;
    message:string;
    status:string;
  };
  openCancel:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPlansCallId:string="";
  getCurrentSubscriptionCallId:string="";
  getChangeSubscriptionCallId:string="";
  cancelSubscriptionCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      plans:[],
      currentPlan:{
        id:81,
        name:"year",
        planId:"plan_QmRdK5qwNX2acE",
        validTill:"15 July 2024"
      },
      modalConfirm:{
        open:false,
        plan:"",
        price:0,
        interval:"",
        planId:""
      },
      tostifymessage:{
        show:false,
        message:"",
        status:""
      },
      openCancel:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.getPlansCallId){
        if(responseJson?.length>0){
         this.setState({plans:responseJson});  
        }
      }
      if(apiRequestCallId===this.getCurrentSubscriptionCallId){
        if(responseJson?.data){
         
        }
      }
      if(apiRequestCallId===this.getChangeSubscriptionCallId){
        this.handleGetChangeSubscriptionCall(message);
      }
      if(apiRequestCallId===this.cancelSubscriptionCallId){
        this.handleCancelSubscriptionCallIdCall(message)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const path = window.location.pathname
    this.getSubscriptionDetails();
      this.handleGetPlans()
      this.handleToast();
  };
  handleGetChangeSubscriptionCall=(message:Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.message){
      localStorage.setItem("toastMessage",JSON.stringify({
        show:true,
        message:"Your subscription has been successfully changed.",
        status:"success"
      }))
      this.handleNavigation("Customisableusersubscriptions");
    }else{
      this.setState({tostifymessage:{
        show:true,
        message:"Something went wrong",
        status:"error"
      }},()=>{
        this.closeMessageBox();
      });
    }
  }
  handleCancelSubscriptionCallIdCall=(message:Message)=>{
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.message){
      this.handleNavigation("SubscriptionDetails");
    }else{
      this.setState({tostifymessage:{
        show:true,
        message:"Something went wrong",
        status:"error"
      }},()=>{
        this.closeMessageBox();
      });
    }
  }
  closeMessageBox = () => {
      setTimeout(()=>{
       localStorage.removeItem("toastMessage");
       this.setState({tostifymessage:{
        show:false,
        message:"",
        status:""
       }})
      },2000);
    }
  handleToast=async()=>{
    const response=  localStorage.getItem("toastMessage");
    let data:any;
    if(response){
       data =await JSON.parse(response)
    }
    if(data.show){
      this.setState({tostifymessage:{
        show:data.show,
        message:data.message,
        status:data.status
      }},()=>{
        this.closeMessageBox()
      });
    }
  };
  handleNavigation=(route:string)=>{
    console.log("nav sai ",route);
    
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  };
  handleGetPlans=()=>{
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlansCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/plans`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSubscriptionDetails=()=>{
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrentSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/user_subscriptions`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChangeSubscription=()=>{
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChangeSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/user_subscriptions/81/upgrade?plan_id=${this.state.modalConfirm.planId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleCloseModal();
  };
  handleOpenModal=(plan:string,price:number,interval:string,planId:string)=>{
   this.setState({modalConfirm:{
    open:true,
    plan:plan,
    price:price,
    interval:interval,
    planId:planId
   }})
  };
  handleCloseModal=()=>{
    this.setState({modalConfirm:{
      open:false,
      plan:"",
      price:0,
      interval:"",
      planId:""
     }})
  };
  cancellationModalOpen=()=>{
    this.setState({openCancel:true});
  };
  cancellationModalClose=()=>{
    this.setState({openCancel:false});
  };
  confirmCancellation=()=>{
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_user_subs/user_subscriptions/${this.state.currentPlan.id}/cancel?plan_id=${this.state.currentPlan.planId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.cancellationModalClose();
  }
  // Customizable Area End
}
