// Customizable Area Start
import React from "react";
import {
  AppBar,
  Typography,
  Container,
  TextField,
  OutlinedTextFieldProps,
  Grid,
  Button,
  FormControl,
  Select,
  SelectProps,
  MenuItem,
  Box,
  Divider,
  Stepper,
  Step,
  StepIcon,
  StepConnector,
  StepLabel,
  DialogActions,
  Dialog,
  Snackbar,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  Check,
  ArrowBackIos,
  Clear
} from "@material-ui/icons";
import WorldFlag from "react-world-flags";
import { Formik } from "formik";
import * as yup from "yup";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {  withStyles } from '@material-ui/core/styles';
import CreateEditProjectPortfolioController, {
  Props,
  configJSON,
} from "./CreateEditProjectPortfolioController";
import Loader from "../../../components/src/Loader.web";
import CustomArrowIcon from '@material-ui/icons/ExpandMore';


const FormContainer = styled(Container)({
  marginBlock: "20px",
  borderRadius: "8px",
  maxWidth: "-webkit-fill-available",
  backgroundColor: "#fff",
  marginInline: "20px",
  fontFamily: "'Poppins', sans-serif",
  padding: '10px',
  minWidth: '100%'
});

const CustomDivider = styled(Divider)({
  height: "0.5px",
  marginBlock: 0,
  marginInline: "20px",
  backgroundColor: '#E2E8F0'
})

const Section = styled(Box)({
  marginBottom: "20px",
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  fontFamily: "'Poppins', sans-serif",
});

const SaveButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  marginRight: "13px",
  fontWeight: 600,
  fontSize: "16px",
  height: "44px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  borderWidth:0,
});
const SaveButtonEnable = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#237182",
  color: "#fff",
  textTransform: "none",
  display:"flex",
  fontSize: "16px",
  fontWeight: 600,
  height: "44px",
  padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#237182",
  },
  border: "1px solid #C5E0E7",
  marginRight: "13px",
  letterSpacing: 0,
  boxShadow: "none",
  borderWidth:0,
});
const SectionLabel = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "15px",
});
const InputLabel = styled(Typography)({
  color: "#64748B",
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "'Poppins', sans-serif",
  marginBottom: "2px",
});

const DisabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#F1F5F9",
  color: "#64748B",
  textTransform: "none",
  fontSize: "16px",
  marginRight: '30px',
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#F1F5F9",
  },
});

 const styleSheet = {
   logoutButton: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "15px",
    fontWeight:600,
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "148px",
    padding: "10px 16px 10px 16px",
  } as const,
  logoutButton2: {
    background: "#237182",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "121px",
    padding: "10px 16px 10px 16px",
    fontWeight:600,
  } as const,
  cancelButton: {
    backgroundColor: "#E2E8F0",
    color: "#325962",
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    height: "56px",
    borderRadius: "8px",
    width: "96px",
    fontWeight:600,
  } as const,
  title: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0F172A",
    fontFamily: "Poppins",
    lineHeight: "32px",
    letterSpacing: "-0.005em"
  },
 }

const EnabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#237182",
  color: "#fff",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  width: "69px",
  height: "44px",
  marginRight: '30px',

  padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#237182",
  },
});

const Root = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  width: "33.53%",
  backgroundColor: "#F0F5F7",
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepConnector-lineHorizontal":{
    borderTopWidth:'2px'
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 0,
  },
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#0F172A",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepLabel-label.MuiStepLabel-active": {
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    color: "#0F172A",
    width:'140px'
  }
});

interface CustomTextFieldProps extends OutlinedTextFieldProps {
  hasValue: boolean;
}

export const CustomTextField = styled(({ hasValue, ...otherProps }: CustomTextFieldProps) => (
  <TextField {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  "& .MuiOutlinedInput-root": {
    height: '56px',
    borderRadius: '8px',
    borderColor: '#CBD5E1',
    backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
}));

export const ProjectTextField = styled(({ hasValue, ...otherProps }: CustomTextFieldProps) => (
  <TextField {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    borderColor:'#CBD5E1',
    backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1",
    },
  },
}));
interface CustomSelectProps extends SelectProps {
  hasValue: boolean;
}

export const CustomSelect = styled(({ hasValue, ...otherProps }: CustomSelectProps) => (
  <Select {...otherProps} />
))(({ hasValue }: { hasValue: boolean }) => ({
  backgroundColor: hasValue ? '#FFFFFF' : '#FCFDFE',
  borderRadius: '8px',
  fontFamily: "'Poppins', sans-serif",
  "& .MuiSelect-select:focus": {
    backgroundColor: "white",
    borderRadius: 0,
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: '8px',
    "& fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&:hover fieldset": {
      border: "1px solid #CBD5E1",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #CBD5E1 !important",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1 !important",
  },
  "& .MuiSelect-icon": {
    color: "#94A3B8",
  },
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      borderColor: '#0F172A',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0F172A',
    },
  },
  line: {
    borderColor: '#94a3b8',
    borderTopWidth: 2,
    width:"40px"
  },
}))(StepConnector);

const Wrapper = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
});

const formSchema = (conditionalValidate:any) =>
  yup.object().shape({
    projectName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z\d]+$/, "Project name must be alphanumeric")
      .required("Please enter project name"),

    firstName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Name must contain alphabets only")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter first name"),
        otherwise: yup.string(),
      }),

    lastName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "Last name must contain alphabets only")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter last name"),
        otherwise: yup.string(),
      }),

    email: yup
      .string()
      .trim()
      .email("Invalid email format")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter an email"),
        otherwise: yup.string(),
      }),

    phoneNumber: yup
      .string()
      .trim()
      .matches(/^\d+$/, "Phone number must contain numbers only")
      .min(10, "Phone number is too short")
      .max(15, "Phone number is too long")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter phone number"),
        otherwise: yup.string(),
      }),

    postCode: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, "Post code must be alphanumeric")
      .required("Please enter post code"),

    country: yup
      .string()
      .required("Please select a country"),

    state: yup
      .string()
      .required("Please select a state"),

    streetName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9 ]+$/, "Street name must be alphanumeric")
      .required("Please enter street name"),

    city: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z]+$/, "City must contain alphabets only")
      .required("Please enter city"),

    projectDescription: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9 ]*$/, "Project description must be alphanumeric")
  });

export default class CreateEditProjectPortfolio extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const getSize = () => {
      return active || completed ? 32 : 24;
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: getSize(),
      height: getSize(),
      fontFamily: "'Poppins', sans-serif",
      marginRight: active || completed ? "5px" : 0
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };

  render() {
    const steps = this.getSteps();
    const {
      activeStep,
      completed,
      formValues: {
        firstName,
        projectName,
        projectDescription,
        lastName,
        postCode,
        city,
        email,
        streetName,
        phoneNumber,
        country,
        state,
        countryCode,
      },
      planId
    } = this.state;
    return (
      <Wrapper>
        <Loader loading={this.state.loading} />
        <MyProfileHeader navigation={this.props.navigation} handleDialogOpen={this.handleOpenDialogLeave} tabName="Projects">
        {this.state.openDialogLeave && <Dialog PaperProps={{
                      style: {
                        width: '584px',
                        maxWidth: '584vw',
                        height: '245px',
                        maxHeight: '240vh',
                        borderRadius: "16px",
                        padding: "40px 32px 40px 32px",
                        color: "#FFFFFF"
                      }
                    }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 2 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Are you sure you want to leave without saving?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px",
                          marginTop: "8px",
                          marginRight:"15px"
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                          marginTop: "80px",
                          gap: "10px"
                        }}>
                          <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button data-test-id="savedraftbtn"  style={styleSheet.logoutButton} onClick={this.handleSaveDraft}>
                            Save Changes
                          </Button></DialogActions>

                      </Box>
         </Dialog>}
         <Snackbar
    open={this.state.snackbarOpen}
    autoHideDuration={6000}
    message={this.state.snackbarMessage}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
/>
          <Formik
            initialValues={this.state.formValues}
            validationSchema={formSchema(planId != '3')}
            onSubmit={this.submitData}
            enableReinitialize
            validateOnMount={true}
            data-test-id="formik"
          >
            {({
              handleSubmit,
              errors,
              setFieldTouched,
              setFieldValue,
              touched,
              isValid
            }) => (
              <>
                <Box
                  style={{
                    cursor: "pointer",
                    fontFamily: "Poppins, sans-serif",
                    display: "flex",
                    backgroundColor: "#F0F5F7",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginInline: "40px",
                    marginTop: '20px'
                  }}>
                  <Typography
                    data-test-id="backButon2"
                    onClick={() => this.goBack2()}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      display: "flex",
                      alignItems: "baseline",
                      width: "15%",
                      fontSize:'14px',
                      fontWeight: 600,
                      color:'#334155'
                    }}
                  >
                    <ArrowBackIos style={{ height: "12px", width: "20px",color:'#334155' }} />
                    Back
                  </Typography>
                  {planId !=='3' && <Root>
                    <Stepper
                      connector={<QontoConnector/>}
                      activeStep={activeStep}
                      style={{
                        backgroundColor: "#F0F5F7",
                        fontFamily: "'Poppins', sans-serif",
                        padding: 0,
                        width:'40px',
                      }}
                    >
                      {steps.map((placeholder, index) => (
                        <Step key={placeholder}
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0
                        }}>
                          <ActiveStepLabel
                            StepIconComponent={(props) => (
                              <this.CustomStepIcon
                                {...props}
                                active={activeStep === index}
                                completed={!!completed[index]}
                              />
                            )}
                          >
                            {activeStep === index && placeholder}
                          </ActiveStepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Root>}
                  <Box style={{ display: "flex" }}>
                    {this.saveDraftDisable() ?
                    <SaveButtonEnable
                      data-test-id="btnSubmitProjectData"
                      type="submit"
                      onClick={this.handleOpenDialog}
                    >
                      Save Draft
                    </SaveButtonEnable>
                    :
                    <SaveButton variant="contained">Save Draft</SaveButton>
                    }
                    <Dialog PaperProps={{
                      style: {
                        height: "232px",
                        width: '584px',
                        borderRadius: "16px",
                        padding: "40px 32px",
                        color: "#FFFFFF",
                        boxSizing:"border-box"
                      }
                    }} open={this.state.openDialog} onClose={this.handleCloseDialog}>
                      <Box sx={{ padding: 0 }}>
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Do you want to save your draft before exiting?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px"
                        }}><Clear /></Typography></Box>
                        <DialogActions style={{
                          marginTop: "32px",
                          gap: "16px",
                          padding: "0px"
                        }}>
                          <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button  data-test-id="savedraftbtn"  style={styleSheet.logoutButton2} onClick={this.handleSaveDraft}>
                            Save Draft
                          </Button></DialogActions>

                      </Box>
                      </Dialog>
                      
                   
                    {isValid ?
                    <EnabledNextButton
                      data-test-id="btnSubmitProjectData"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Next
                    </EnabledNextButton>
                    :
                    <DisabledNextButton
                      data-test-id="btnSubmitProjectData"
                    >
                      Next
                    </DisabledNextButton>
                    }
                  </Box>
                </Box>
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#F0F5F7",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                ></AppBar>
                <Box sx={{marginLeft: '24px', marginRight: '70px'}}>
                <FormContainer>
                  <Section>
                    <SectionLabel>Project Information</SectionLabel>
                    <InputLabel>Project Name *</InputLabel>
                    <CustomTextField
                      autoComplete='off'
                      error={this.errorDisplay(errors, touched, "projectName")}
                      value={projectName}
                      hasValue={!!projectName}
                      variant="outlined"
                      name="projectName"
                      data-test-id="projectName"
                      onChange={(e) => this.handleInputChange(e, setFieldValue)}
                      onBlur={() => setFieldTouched("projectName")}
                      fullWidth
                      placeholder="Enter project name"
                      required
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    {this.errorText(errors, touched, "projectName")}
                  </Section>
                  <CustomDivider />
                  {planId !=='3' && <><Section>
                    <SectionLabel>Client Details</SectionLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Name *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          error={this.errorDisplay(
                            errors,
                            touched,
                            "firstName"
                          )}
                          value={firstName}
                          hasValue={!!firstName}
                          variant="outlined"
                          name="firstName"
                          data-test-id="firstName"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("firstName")}
                          fullWidth
                          placeholder="Enter name"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "firstName")}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputLabel>Last name *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          error={this.errorDisplay(errors, touched, "lastName")}
                          value={lastName}
                          hasValue={!!lastName}
                          name="lastName"
                          data-test-id={"lastName"}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("lastName")}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter last name"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "lastName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Email *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          error={this.errorDisplay(errors, touched, "email")}
                          value={email}
                          hasValue={!!email}
                          name="email"
                          variant="outlined"
                          data-test-id="emailTest"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("email")}
                          fullWidth
                          placeholder="Enter email"
                          type="email"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "email")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Phone number *</InputLabel>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          <CustomSelect
                            IconComponent={()=> <CustomArrowIcon style={{fontSize:'16px', color:'#64748B', padding:'8px'}}/>}
                            style={{
                              width: "20%",
                              height: "55px",
                            }}
                            data-test-id="inputCountryCode"
                            variant="outlined"
                            name="countryCode"
                            value={countryCode}
                            hasValue={Boolean(countryCode)}
                            onChange={this.updateCountryCode}
                            onBlur={() => setFieldTouched("countryCode")}
                            displayEmpty
                            inputProps={{
                              style: {
                                fontFamily: "'Poppins', sans-serif"
                              },
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  height: "323px",
                                  width: "115px",
                                  fontFamily: "'Poppins', sans-serif",   
                                },
                              },
                            }}
                          >
                            {configJSON.countryCodesArray.map(
                              (country: {
                                dial_code: {} | null | undefined;
                                code: React.Key | null | undefined;
                              }) => (
                                <MenuItem
                                  value={country.dial_code as string}
                                  key={country.code}
                                >
                                  <Box display="flex" alignItems="center">
                                    <WorldFlag
                                      code={country.code as string}
                                      height="18"
                                      width="24"
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                        borderRadius: "1px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                      }}
                                    >
                                      &nbsp;{country.dial_code}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              )
                            )}
                          </CustomSelect>
                          <CustomTextField
                            autoComplete='off'
                            error={this.errorDisplay(
                              errors,
                              touched,
                              "phoneNumber"
                            )}
                            data-test-id="phoneNumber"
                            value={phoneNumber}
                            hasValue={!!phoneNumber}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value))
                                this.handleInputChange(e, setFieldValue);
                            }}
                            onBlur={() => setFieldTouched("phoneNumber")}
                            name="phoneNumber"
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                              width: "78%",
                            }}
                            variant="outlined"
                            fullWidth
                            placeholder="00000 00000"
                            required={planId != '3'}
                            inputProps={{
                              maxLength: 10,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              style: {
                                fontFamily: "'Poppins', sans-serif",
                              }
                            }}
                          />
                        </Box>
                        {this.errorText(errors, touched, "phoneNumber")}
                      </Grid>
                    </Grid>
                  </Section>
                  <CustomDivider/></>}
                  <Section>
                    <SectionLabel>Address</SectionLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InputLabel>Street Name *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          error={this.errorDisplay(
                            errors,
                            touched,
                            "streetName"
                          )}
                          value={streetName}
                          hasValue={!!streetName}
                          variant="outlined"
                          name="streetName"
                          data-test-id="streetName"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("streetName")}
                          fullWidth
                          placeholder="Enter name street"
                          required
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "streetName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>City *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          error={this.errorDisplay(errors, touched, "city")}
                          value={city}
                          hasValue={!!city}
                          name="city"
                          variant="outlined"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("city")}
                          fullWidth
                          placeholder="Enter city"
                          required
                          data-test-id="city-input"
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "city")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Post code *</InputLabel>
                        <CustomTextField
                          autoComplete='off'
                          data-test-id="postCode"
                          error={this.errorDisplay(errors, touched, "postCode")}
                          variant="outlined"
                          name="postCode"
                          value={postCode}
                          hasValue={!!postCode}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("postCode")}
                          fullWidth
                          placeholder="Enter post code"
                          required
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "postCode")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                          <InputLabel>State *</InputLabel>
                          <CustomSelect
                            IconComponent={CustomArrowIcon}
                            data-test-id="stateinput"
                            name="state"
                            value={state}
                            hasValue={Boolean(state)}
                            onChange={(e) =>
                              this.handleInputChange(e, setFieldValue)
                            }
                            onBlur={() => setFieldTouched("state")}
                            error={this.errorDisplay(errors, touched, "state")}
                            displayEmpty
                            inputProps={{
                              style: { fontFamily: "'Poppins', sans-serif" },
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select state
                              </Typography>
                            </MenuItem>
                            {this.state.stateList.map((state, index) => (
                              <MenuItem
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                                key={index}
                                value={state.isoCode}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </FormControl>
                        {this.errorText(errors, touched, "state")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                          <InputLabel>Country *</InputLabel>
                          <CustomSelect
                            IconComponent={CustomArrowIcon}
                            data-test-id="countryName"
                            name="country"
                            value={country}
                            hasValue={Boolean(country)}
                            onChange={(e) => this.handleInputChange(e, setFieldValue)}
                            onBlur={() => setFieldTouched("country")}
                            error={this.errorDisplay(errors, touched, "country")}
                            displayEmpty
                            inputProps={{
                              style: { fontFamily: "'Poppins', sans-serif" },
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select country
                              </Typography>
                            </MenuItem>
                            {this.state.countryList.map((country, index) => (
                              <MenuItem
                                key={index}
                                value={country.isoCode}
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </FormControl>
                        {this.errorText(errors, touched, "country")}
                      </Grid>
                    </Grid>
                  </Section>
                  <CustomDivider/>
                  <Section>
                    <SectionLabel>Additional Information</SectionLabel>
                    <InputLabel>Description</InputLabel>
                    <ProjectTextField
                      autoComplete='off'
                      data-test-id="projectDescription"
                      value={projectDescription}
                      hasValue={!!projectDescription}
                      onChange={(e) => this.handleInputChange(e, setFieldValue)}
                      onBlur={() => setFieldTouched("projectDescription")}
                      name="projectDescription"
                      variant="outlined"
                      fullWidth
                      placeholder="This is text area"
                      multiline
                      error={this.errorDisplay(
                        errors,
                        touched,
                        "projectDescription"
                      )}
                      rows={2}
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    {this.errorText(errors, touched, "projectDescription")}
                  </Section>
                </FormContainer>
                </Box>
              </>
            )}
          </Formik>
        </MyProfileHeader>
      </Wrapper>
    );
  }
}

// Customizable Area End
