Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.textOne = "Detailed cost estimates by Quantity Surveyor";
exports.textTwo = "Personalized support ";
exports.textThree = "Access to all project files ";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.changeSubscription = "Change Subscription";
exports.chooseSubscription = "Choose Subscription";
exports.currentSubscription = "Current Subscription";
exports.cancelSubscription = "Cancel Subscription";
exports.perMonth = "per month";
exports.back = "Back";
exports.teamPlan = "Team Plan";
exports.confirmSubscriptionChange  = "Confirm Subscription Change  ";
exports.confirm  = "Confirm";
exports.subscriptionPricing  = "Subscription & Pricing";
exports.status  = "Status: ";
exports.renew  = "Renew at: ";
exports.endDate = "Subscription ends on";
exports.cancellationModalTxt = "You will lose access to the detailed cost plan from a professional Quantity Surveyor. Your current plan will remain active until the end of the billing cycle.";
exports.professional  = "Perfect plan for Professional";
exports.canceled = "CANCELED";
exports.active = "ACTIVE";
exports.subscriptionCanceled="Subscription Cancelled";
exports.returnToDashboard="Return to Dashboard";
// Customizable Area End
