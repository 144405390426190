import React from "react";

// Customizable Area Start
import { Box, Typography,Button, styled, Grid, Modal } from "@material-ui/core";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {backArrow,crossIcon} from "./assets";
import MessageBox from "../../../components/src/messageBox";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController.web";

export default class SubscriptionList extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPlanButton=(type:string,price:number,interval:string,planId:string)=>{
    if(type.toLowerCase().includes(this.state.currentPlan.name)){
      return <Button style={{...styles.cancelBtn("#E0EDF0","#325962"),textTransform:"capitalize"}} disabled={true}>{configJSON.currentSubscription}</Button>
    }else{
       return <Button data-test-id="change-sub-btn" style={{...styles.cancelBtn("#237182","white"),textTransform:"capitalize",}} 
           onClick={()=>this.handleOpenModal(type,price,interval,planId)}
           >{configJSON.chooseSubscription}</Button>
    }
  };
  renderPerMonth=(price:number,type:string)=>{
    switch(true){
     case type.toLowerCase().includes("half"):return (
      <Typography style={styles.fontStyles("14px",300,"#475569")}>£{(price / 6).toFixed(2)} {configJSON.perMonth}</Typography>);
      case type.toLowerCase().includes("quarter"):return (
        <Typography style={styles.fontStyles("14px",300,"#475569")}>£{(price / 3).toFixed(2)} {configJSON.perMonth}</Typography>);
      case type.toLowerCase().includes("yearly")||type.toLowerCase().includes("annual"):return (
        <Typography style={styles.fontStyles("14px",300,"#475569")}>£{(price / 12).toFixed(2)} {configJSON.perMonth}</Typography>);
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MyProfileHeader navigation={this.props.navigation} showSidebar={true}>
    {this.state.tostifymessage.show && <MessageBox message={this.state.tostifymessage.message} status={this.state.tostifymessage.status}/>}
      <Box style={styles.mainContainer}>
      <Box data-test-id="backBtn" style={styles.backBox} onClick={()=>this.handleNavigation("Customisableusersubscriptions")}>
        <img src={backArrow} width={"20px"} height={"20px"}/>
        <Typography data-test-id="backTxt" style={styles.fontStyles("14px",600,"#334155")}>{configJSON.back}</Typography>
      </Box>
      <Box style={styles.detailsContainer }>
        <Typography style={styles.fontStyles("20px" ,700,"#0F172A")}> {configJSON.subscriptions}:</Typography>
        <Box style={styles.cardsBox}>
        <Grid container spacing={2}>
          {this.state.plans.length>0&&this.state.plans.map((item,index)=>(
          <Grid item xs={12} md={6} lg={4}>
          <Box style={{...styles.planCard}}>
        <Typography data-test-id={`planName-${index}`} style={styles.fontStyles("20px",700,"#1E293B")}>{item.name}</Typography>
        <Box style={styles.centerBox}>
          <Box style={{display:"flex",alignItems:"center"}}>
            <Typography style={styles.fontStyles("30px",700,"#334155")}>£{item.price} </Typography>
            <Typography style={styles.fontStyles("16px",300,"#475569")}> /{item.interval}</Typography>
          </Box>
          {this.renderPerMonth(item.price,item.name)}
        <Typography style={styles.fontStyles("14px",300,"#475569")}>{configJSON.teamPlan}</Typography>
        </Box>
        {this.renderPlanButton(item.name,item.price,item.interval,item.stripe_plan_id)}
        </Box>  
          </Grid>
          ))}
        </Grid>
        </Box>
      </Box>
      </Box>
      <Modal open={this.state.modalConfirm.open} >
       <Box style={styles.modalPaper}>
       <Box style={{display:"flex"}}>
        <Typography style={{...styles.fontStyles("24px",700,"#0F172A"),marginRight:"auto"}}>{configJSON.confirmSubscriptionChange}</Typography>
        <img data-test-id="closeModal" src={crossIcon} width={"20px"} height={"20px"} onClick={()=>this.handleCloseModal()}/>
       </Box>
       <Typography style={{...styles.fontStyles("16px",300,"#1E293B"),marginTop:"16px"}}>
       By confirming, your current subscription will be changed to the {this.state.modalConfirm.plan}. The new plan will take effect immediately, and you will be billed  <Box component={"span"} style={styles.fontStyles("16px",700,"#1E293B")}>£{this.state.modalConfirm.price}</Box> per {this.state.modalConfirm.interval}.
       </Typography>
       <Box style={{display:"flex",gap:"16px",marginTop:"40px"}}>
        <Button data-test-id="backModalBtn" style={{...styles.modalBtn("#E0EDF0","#325962"),marginLeft:"auto",textTransform:"capitalize"}} onClick={()=>this.handleCloseModal()}>{configJSON.back}</Button>
        <Button data-test-id="confirmPlan" style={{...styles.modalBtn("#237182","white"),textTransform:"capitalize"}} onClick={()=>this.handleChangeSubscription()}>{configJSON.confirm}</Button>
       </Box>
       </Box>
      </Modal>
</MyProfileHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles={
  mainContainer:{
    boxSizing:"border-box",
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins",
    marginLeft:"6rem",
    display:"flex",
    flexDirection:"column",
    gap:"2rem",
    backgroundColor:"#f0f5f7",
    height:"100vh",
  }as const,
  detailsContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"1.5rem",
    backgroundColor:"#FFFFFF",
    boxSizing:"border-box",
    padding:"25px",
    borderRadius:"18px",
    width:"100%",
    height:"fit-content"
  }as const,
  fontStyles:(size:string,weight:number,color:string="#0F172A")=>{
    return {
     fontFamily:"Poppins",
     fontWeight:weight,
     fontSize:size,
     color:color
    }
  },
  cardsBox:{
   display:"flex",
   justifyContent:"space-between",
   flexDirection: "row",
    // Media query for tablets (width <= 768px)
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  }as const,
  planCard : {
    width: "100%",
    height: "272px",
    boxSizing:"border-box",
    padding: "24px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    gap: "24px",
    borderRadius: "12px",
    border: "1px solid #CBD5E1",
  } as const,
  cancelBtn:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"10px 15px",
    borderRadius:"9px",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    width:"100%",
    cursor:"pointer",
    marginTop:"auto",
  }},
  modalBtn:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"16px",
    borderRadius:"9px",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    cursor:"pointer"
  }},
  centerBox:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    gap:"8px"
  } as const,
  modalPaper:{
    position: 'absolute',
    width:"580px",
    backgroundColor: "#fff",
    padding: "40px 32",
    borderRadius:"16px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)"
  } as const,
  backBox:{
    display:"flex",
    gap:"6px",
    alignItems:"center",
    cursor:"pointer"
  },
}
// Customizable Area End